<!--explain：;@author: cy; @version: 1.0; date: 2022/4/1;-->
<template>
  <div>
    <v-tabs v-model="tab" class="rounded-lg elevation-2" @change="tabChange">
      <div
        v-if="tabList.length > 0"
        style="
          width: 100%;
          position: absolute;
          bottom: 0;
          background-color: #c2c2c2;
          height: 2px;
        "
      ></div>
      <v-tab v-for="(item, index) in tabList" :key="index">
        <div
          class="tab-title"
          :style="{
            color:
              tab === index ? $vuetify.theme.themes.light.primary.base : '',
          }"
        >
          {{ item.moduleName }}
        </div>
      </v-tab>
      <v-tabs-items v-model="tab" class="pa-4">
        <v-tab-item v-for="item in tabList" :key="item.moduleUuid">
          <div v-for="sub in item.analysisItems" :key="sub">
            <v-form :ref="item.moduleUuid" class="form">
              <div class="mt-10 mb-5 text-subtitle-5 d-flex align-center">
                <div
                  style="height: 18px; width: 4px"
                  class="primary mr-1 text-h2"
                ></div>
                {{ showModule(sub) }}
              </div>
              <div v-if="sub === 'kaba'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>检测项目：</div>
                  <v-checkbox
                    v-model="formData.kaba.twoperson"
                    class="ml-2"
                    label="双人"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="formData.kaba.useBillCounter"
                    class="mx-2"
                    label="使用点钞机"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="formData.kaba.useCashbox"
                    label="打开钱箱"
                  ></v-checkbox>
                </div>
              </div>
              <div v-if="sub === 'room_io'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>单人出现校验时长(秒)：</div>
                  <k-text-input
                    v-model.number="formData.room_io.singleAlarmTime"
                    type="number"
                  />
                </div>
              </div>
              <div v-if="sub === 'room_insp'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>单人出现校验时长(秒)：</div>
                  <k-text-input
                    v-model.number="formData.room_insp.singleAlarmTime"
                    type="number"
                  />
                </div>
              </div>
              <div v-if="sub === 'room_atm'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>单人出现校验时长(秒)：</div>
                  <k-text-input
                    v-model.number="formData.room_atm.singleAlarmTime"
                    type="number"
                  />
                </div>
              </div>
              <div v-if="sub === 'room_atmclr'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>单人出现校验时长(秒)：</div>
                  <k-text-input
                    v-model="formData.room_atmclr.singleAlarmTime"
                    type="number"
                  />
                </div>
              </div>
              <!--              现金督查-->
              <div v-if="sub === 'debtor_tran'">
                <div class="d-flex px-3 my-4 align-center">
                  <v-checkbox
                    v-model="formData.debtor_tran.enable"
                    label="启用"
                  ></v-checkbox>
                </div>
              </div>
              <div v-if="sub === 'risk_tran'">
                <div class="d-flex px-3 my-4 align-center">
                  <k-text-input
                    v-model="formData.risk_tran.doubleAuthTime"
                    label-width="200px"
                    input-width="250px"
                    type="number"
                    label="本地授权(双人出现时间)"
                  />
                  <k-text-input
                    v-model="formData.risk_tran.customerSitTime"
                    label-width="200px"
                    input-width="250px"
                    type="number"
                    label="人员出现时间"
                  />
                </div>
                <div class="d-flex">
                  <div class="mr-2 pl-9" style="width: 200px">
                    分析交易码配置:
                  </div>
                  <div>
                    <k-crud-table
                      :headers="columns"
                      :items="formData.risk_tran.tradeCodeAmountList"
                      :paginationshow="false"
                    >
                      <template #actions="{ item, index }">
                        <k-primary-button
                          icon="mdi-delete"
                          type="error"
                          :mall="true"
                          :isicon="true"
                          @click="delTradeCode(item, index)"
                        />
                      </template>
                      <template #createdTime="{ item }">
                        <k-select
                          v-model="item.tradeCode"
                          label-width="200px"
                          input-width="250px"
                          :items="tradeCodeList"
                          @change="tradeCodeChange"
                        />
                      </template>
                      <template #roleVos="{ item }">
                        <k-text-input
                          v-model.number="item.minAmount"
                          label-width="200px"
                          input-width="250px"
                          type="number"
                        />
                      </template>
                      <template #[`append`]>
                        <tr class>
                          <td class="text-center info--text">交易码类型</td>
                          <td class="text-center info--text">大额金额</td>
                          <td class="text-center">
                            <k-primary-button
                              icon="mdi-plus"
                              :mall="true"
                              @click="addTradeCode"
                            />
                          </td>
                        </tr>
                      </template>
                    </k-crud-table>
                  </div>
                </div>

                <!--                <div-->
                <!--                  v-for="(risk, indexRisk) in formData.risk_tran-->
                <!--                    .tradeCodeAmountList"-->
                <!--                  :key="indexRisk"-->
                <!--                  class="d-flex px-3 my-4 align-center mt-3"-->
                <!--                >-->
                <!--                  <k-text-input-->
                <!--                    v-model.number="risk.minAmount"-->
                <!--                    label-width="200px"-->
                <!--                    input-width="250px"-->
                <!--                    type="number"-->
                <!--                    label="大额金额"-->
                <!--                  />-->
                <!--                  <k-select-->
                <!--                    v-model="risk.tradeCode"-->
                <!--                    label-width="200px"-->
                <!--                    input-width="250px"-->
                <!--                    label="分析交易码"-->
                <!--                    :items="tradeCodeList"-->
                <!--                    @change="tradeCodeChange"-->
                <!--                  />-->
                <!--                  <k-primary-button-->
                <!--                    v-if="-->
                <!--                      formData.risk_tran.tradeCodeAmountList.length - 1 !== 0-->
                <!--                    "-->
                <!--                    class="ml-3"-->
                <!--                    icon="mdi-delete"-->
                <!--                    type="error"-->
                <!--                    :isicon="true"-->
                <!--                    @click="delTradeCode(indexRisk)"-->
                <!--                  />-->
                <!--                  <k-primary-button-->
                <!--                    v-if="-->
                <!--                      indexRisk ===-->
                <!--                      formData.risk_tran.tradeCodeAmountList.length - 1-->
                <!--                    "-->
                <!--                    :isicon="true"-->
                <!--                    icon="mdi-plus"-->
                <!--                    class="ml-3"-->
                <!--                    @click="addTradeCode(indexRisk)"-->
                <!--                  />-->
                <!--                </div>-->
              </div>
              <!--              服务员监督 -->
              <div v-if="sub === 'leave_post'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>持续时长(秒)：</div>
                  <k-text-input
                    v-model.number="formData.leave_post.duration"
                    type="number"
                  />
                </div>
              </div>
              <div v-if="sub === 'play_phone'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>持续时长(秒)：</div>
                  <k-text-input
                    v-model.number="formData.play_phone.duration"
                    type="number"
                  />
                </div>
              </div>
              <div v-if="sub === 'foot_up'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>持续时长(秒)：</div>
                  <k-text-input
                    v-model.number="formData.foot_up.duration"
                    type="number"
                  />
                </div>
              </div>
              <div v-if="sub === 'eate_takeout'">
                <div class="d-flex px-3 my-4 align-center">
                  <div>持续时长(秒)：</div>
                  <k-text-input
                    v-model.number="formData.eate_takeout.duration"
                    type="number"
                  />
                </div>
              </div>
              <!--              厅堂助手-->
              <div v-if="sub === 'site_flow'">
                <div class="d-flex px-3 my-4 align-center">
                  <k-text-input
                    v-model.number="formData.site_flow.yellowWarningNumber"
                    label-width="150px"
                    input-width="250px"
                    label="预警人数(黄色)"
                    type="number"
                  />
                  <k-text-input
                    v-model.number="formData.site_flow.redWarningNumber"
                    label-width="150px"
                    input-width="250px"
                    label="告警人数(红色)"
                    type="number"
                  />
                </div>
              </div>
              <div v-if="sub === 'monmeet'">
                <div class="d-flex px-3 my-4 align-center">
                  <k-text-input
                    v-model.number="formData.monmeet.minTime"
                    label="最低时长(秒)"
                    label-width="150px"
                    input-width="250px"
                    type="number"
                  />
                  <k-text-input
                    v-model.number="formData.monmeet.maxRecordingTime"
                    label-width="150px"
                    input-width="250px"
                    label="最长录制时长(秒)"
                    type="number"
                  />
                </div>
                <div class="d-flex px-3 my-4 align-center">
                  <k-text-input
                    v-model="formData.monmeet.earliestStartTime"
                    label="最早开始时间"
                    label-width="150px"
                    input-width="250px"
                    type="time"
                  />
                  <k-text-input
                    v-model="formData.monmeet.latestStartTime"
                    label-width="150px"
                    input-width="250px"
                    label="最晚开始时间"
                    type="time"
                  />
                </div>
              </div>
            </v-form>
          </div>
          <div class="d-flex justify-end">
            <k-primary-button
              v-if="hasPermission(['analysis.monitor.edit'])"
              class="my-8"
              text="保存"
              @click="saveData"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'Global',
  data() {
    return {
      tab: null,
      tabList: [],
      items: [],
      dict: {},
      tradeCodeList: [],
      columns: [
        {
          text: '交易码类型',
          value: 'createdTime',
          height: '100px',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
        {
          text: '大额金额',
          value: 'roleVos',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
        {
          text: '操作',
          value: 'actions',
          sortable: false,
          align: 'center',
          class: 'primary white--text',
        },
      ],

      formData: {
        kaba: {
          twoperson: false, //双人状态
          useBillCounter: false, //使用点钞机状态
          useCashbox: false, //打开钱箱状态
        },
        //出入库
        room_io: {
          singleAlarmTime: null, //单人出现预警时长(秒)
        },
        //行领导查库
        room_insp: {
          singleAlarmTime: null, //单人出现预警时长(秒)
        },
        //ATM加钞
        room_atm: {
          singleAlarmTime: null, //单人出现预警时长(秒)
        },
        //ATM清机
        room_atmclr: {
          singleAlarmTime: null, //单人出现预警时长(秒)
        },
        //风险交易
        risk_tran: {
          customerSitTime: null, //人员出现时间
          doubleAuthTime: null, //本地授权(双人出现时间)
          tradeCodeAmountList: [
            //交易码集合code不能重复 可空
            {
              tradeCode: '123', //交易码List请求code
              minAmount: null, //大额金额(分析最低金额)
            },
          ],
        },
        //老赖动账
        debtor_tran: {
          enable: false, //启用状态
        },
        //离岗
        leave_post: {
          duration: null, //持续时长(秒)
        },
        //玩手机
        play_phone: {
          duration: null, //持续时长(秒)
        },
        //翘脚
        foot_up: {
          duration: null, //持续时长(秒)
        },
        //吃外卖
        eate_takeout: {
          duration: null, //持续时长(秒)
        },
        //网点客流
        site_flow: {
          yellowWarningNumber: null, //预警人数(黄色)
          redWarningNumber: null, //告警人数(红色)
        },
        //晨会
        monmeet: {
          minTime: null, //最低时长(秒)
          maxRecordingTime: null, //最长录制时长(秒)
          earliestStartTime: null, //最早开始时间（HH:mm）
          latestStartTime: null, //最晚开始时间（HH:mm）
        },
      },
    };
  },
  created() {
    this.getTabs();
    this.getDict();
    this.getTransactionList();
  },
  methods: {
    tabChange(e) {
      this.getInfo(this.tabList[e]);
    },
    delTradeCode(item, index) {
      this.formData.risk_tran.tradeCodeAmountList.splice(index, 1);
      this.tradeCodeChange();
    },
    addTradeCode() {
      const listLen = this.formData.risk_tran.tradeCodeAmountList.length;
      const backListLen = this.tradeCodeList.length;
      if (listLen === backListLen) {
        this.$alert.warning(`当前最多添加${backListLen}条！`);
        return;
      }
      this.tradeCodeChange();
      this.formData.risk_tran.tradeCodeAmountList.push({
        tradeCode: null, //交易码List请求code
        minAmount: null, //大额金额(分析最低金额)
      });
    },
    tradeCodeChange() {
      const codeArr = this.formData.risk_tran.tradeCodeAmountList.map(
        (item) => {
          if (item.tradeCode) return item.tradeCode;
        }
      );
      // if (e === undefined) {
      this.tradeCodeList.map((item) => {
        item.disabled = codeArr.includes(item.value);
      });
      // } else {
      //   this.tradeCodeList.map((item) => {
      //     item.disabled = item.value !== e;
      //   });
      // }
    },
    getInfo(item) {
      this.$api.globalAnalysisConfig.globalAnalysisConfig
        .getInfo({ moduleUuid: item.moduleUuid })
        .then(({ data, code }) => {
          if (code === 200) {
            const queryData = data[0];
            if (queryData?.risk_tran) {
              queryData.risk_tran.tradeCodeAmountList = queryData.risk_tran
                .tradeCodeAmountList || [
                {
                  tradeCode: null,
                  minAmount: null,
                },
              ];
            }
            this.formData = { ...this.formData, ...queryData };
            if (queryData?.risk_tran) {
              this.tradeCodeChange();
            }
            console.log('this.formData---', this.formData);
          }
        });
    },
    saveData() {
      const itemTab = this.tabList[this.tab];
      const formQuery = { ...this.formData };
      const { analysisItems, moduleUuid, moduleCode } = itemTab;
      let query = {
        moduleUuid,
        moduleCode,
        monitorConfigs: [],
      };
      analysisItems.forEach((key) => {
        if (formQuery[key]) {
          query.monitorConfigs.push({
            itemCode: key,
            itemConfig: JSON.stringify({ ...formQuery[key] }),
          });
        }
      });
      console.log('query----', query);
      this.$api.globalAnalysisConfig.globalAnalysisConfig
        .update(query)
        .then(({ code, message }) => {
          if (code === 200) {
            this.$alert.success(message);
          }
        });
    },
    showModule(key) {
      return this.dict.find((item) => {
        return key === item.code;
      }).msg;
    },
    async getTabs() {
      const { data, code } =
        await this.$api.globalAnalysisConfig.globalAnalysisConfig.getTab();
      if (code === 200) {
        this.tabList = data;
      }
    },
    async getDict() {
      const { data, code } =
        await this.$api.globalAnalysisConfig.globalAnalysisConfig.getDict();
      if (code === 200) {
        this.dict = data[0].AnalysisItemsEnum;
      }
    },
    getTransactionList() {
      this.$api.globalAnalysisConfig.globalAnalysisConfig
        .getTransaction()
        .then(({ data, code }) => {
          if (code === 200) {
            if (data?.length) {
              data.map((item) => {
                item.disabled = false;
              });
            }
            this.tradeCodeList = data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  > div:not(:first-child) {
    background-color: #f8faff;
    padding: 15px 20px;
  }
}
.tab-title {
  font-size: 18px;
  font-weight: 400;
  color: #606266;
}
</style>
